import React, { useState, useEffect } from 'react';
import "../CssDesing/DailyTargetLineDetail.css"
import { Carousel, Table } from "react-bootstrap";
import HourlyProductionChart from "../components/HourlyProductionChart";
import targetImage from "../../../../src/image/icons8-target-96.png"
import { useParams } from "react-router-dom"
import { IntlProvider, FormattedMessage } from "react-intl";
import EmployeeTable from '../components/employeeTable';
import "../components/LineTable.css"
import LineTable from '../components/LineTable';

const DailyTargetLineDetail = (props) => {
    const RerfeshDataTimer = 120000;

    const lineInfoByHour = process.env.REACT_APP_LINE_INFO_BY_HOUR_URL;
    const lineEmployeeTable = process.env.REACT_APP_EMPLOYEE_TABLE_URL;
    const dailyTargetLineDetail = "https://api.itmtechsoft.com/api/Lcd/DailyTargetEfficiencyOfTheLine"
    const monthlyTargetLineDetail ="https://api.itmtechsoft.com/api/Lcd/Monthly_EfficiencyOfTheLine"

        


    const [isDataFetched, setIsDataFetched] = React.useState(false);
    const [hasData, setHasData] = useState(false);
    const [error, setError] = useState(null);

    const [dailyTarget, setdailtTarget] = React.useState();
    const [sequance, setSequance] = React.useState();
    const [production, setProduction] = React.useState();
    const [restQuality, setrestQuality] = React.useState();
    const [productivity, setProductivity] = React.useState();
    const [operatorCount, setoperatorCount] = React.useState();
    const [tableData, settableData] = React.useState([]);
    const [lineName, setlineName] = React.useState();

    const [monthlyTarget, setmonthlyTarget] = React.useState();
    const [monthlysequance, setmonthlySequance] = React.useState();
    const [monthlyproduction, setmonthlyProduction] = React.useState();
    const [monthlyrestQuality, setmonthlyrestQuality] = React.useState();
    const [monthlyproductivity, setmonthlyProductivity] = React.useState();


    let { screenId } = useParams();
    let { companyName } = useParams();


    const localization = {
        'tr': {
            productionTitle: "SIRALAMA",
            dailyproductivityTitle: "GÜNLÜK",
            monthlyproductivityTitle: "AYLIK",
            activeperTitle: "AKTİF ÇAL:",
            dailytargetTitle: "GÜNLÜK",
            monthlytargetTitle: "AYLIK",
            firstqualityTitle: "1.KALİTE",
            secondqualityTitle: "2.KALİTE",
            repairTitle: "TAMİR",
            kalan: "KALAN",
            targetName: "HEDEF",
            productionName: "ÜRETİM"
        },
        'tr-TR': {
            productionTitle: "SIRALAMA",
            dailyproductivityTitle: "GÜNLÜK",
            monthlyproductivityTitle: "AYLIK",
            activeperTitle: "AKTİF ÇAL:",
            dailytargetTitle: "GÜNLÜK",
            monthlytargetTitle: "AYLIK",
            firstqualityTitle: "1.KALİTE",
            secondqualityTitle: "2.KALİTE",
            repairTitle: "TAMİR",
            kalan: "KALAN",
            targetName: "HEDEF",
            productionName: "ÜRETİM"
        },
        'en-US': {
            productionTitle: "ARRANGEMENT",
            dailyproductivityTitle: "DAILY",
            monthlyproductivityTitle: "MONTHLY",
            activeperTitle: "ACTIVE PER:",
            dailytargetTitle: "DAILY",
            monthlytargetTitle: "MONTHLY",
            firstqualityTitle: "1.QUALITY",
            secondqualityTitle: "2.QUALITY",
            repairTitle: "REPAIR",
            kalan: "REMAINDER",
            targetName: "TARGET",
            productionName: "PRODUCTION"
        },
        'en': {
            productionTitle: "ARRANGEMENT",
            dailyproductivityTitle: "DAILY",
            monthlyproductivityTitle: "MONTHLY",
            activeperTitle: "ACTIVE PER:",
            dailytargetTitle: "DAILY",
            monthlytargetTitle: "MONTHLY",
            firstqualityTitle: "1.QUALITY",
            secondqualityTitle: "2.QUALITY",
            repairTitle: "REPAIR",
            kalan: "REMAINDER",
            targetName: "TARGET",
            productionName: "PRODUCTION"
        },
        'ar': {
            productionTitle: "إنتاج",
            dailyproductivityTitle: "إنتاجية",
            activeperTitle: ":الموظفون الن",
            targetTitle: "هدف",
            firstqualityTitle: "1.الجودة",
            secondqualityTitle: "2.الجودة",
            repairTitle: "بصلح"
        }
    }

    const defaultLocale = navigator.language;

    // Fallback mekanizması ekleyin
    const supportedLocales = ['tr', 'tr-TR', 'en-US', 'ar', 'en'];
    const fallbackLocale = 'en-US';
    const language = supportedLocales.includes(defaultLocale) ? defaultLocale : fallbackLocale;

    React.useEffect(() => {
        const fetchLCDData = () => {
            var url = ``;
            if (companyName == null) {
                url = `${dailyTargetLineDetail}?lcdNo=${screenId}`;
            }
            else {
                url = `${dailyTargetLineDetail}?lcdNo=${screenId}&companyName=${companyName}`;
            }
            fetch(url)
                .then((response) => response.json())
                .then((respData) => {
                    const respSequance = respData?.sequent || "";
                    const respDailyTarget = respData?.target || "";
                    const respproductivity = respData?.productivity || [];
                    const respproduction = respData?.production || [];
                    const respRestQuality = respDailyTarget - respproduction
                    const respemployeeCount = respData?.operatorCount
                    const respLineName = respData?.lineName

                    setSequance(respSequance);
                    setdailtTarget(respDailyTarget);
                    setProductivity(respproductivity);
                    setProduction(respproduction);
                    setrestQuality(respRestQuality);
                    setoperatorCount(respemployeeCount);
                    setlineName(respLineName)
                    
                })

        }

        fetchLCDData();


        if (!isDataFetched) { fetchLCDData(); setIsDataFetched(true); }

        const interval = setInterval(fetchLCDData, RerfeshDataTimer);
        return () => {
            clearInterval(interval);
        };

    }, [ screenId, companyName])

    React.useEffect(() => {
        const fetchMonthlyLCDData = () => {
            var url = ``;
            if (companyName == null) {
                url = `${monthlyTargetLineDetail}?lcdNo=${screenId}`;
            }
            else {
                url = `${monthlyTargetLineDetail}?lcdNo=${screenId}&companyName=${companyName}`;
            }
            fetch(url)
                .then((response) => response.json())
                .then((respData) => {
                    const respmonthlySequance = respData?.sequent || "";
                    const respMonthlyTarget = respData?.target || "";
                    const respMonthlyProductivity = respData?.efficiency || [];
                    const respMonthlyProduction = respData?.production || [];
                    const respMonthlyRestQuality = respMonthlyTarget - respMonthlyProduction

                    setmonthlySequance(respmonthlySequance);
                    setmonthlyTarget(respMonthlyTarget);
                    setmonthlyProductivity(respMonthlyProductivity);
                    setmonthlyProduction(respMonthlyProduction);
                    setmonthlyrestQuality(respMonthlyRestQuality);

                })
        }
        fetchMonthlyLCDData();

        if (!isDataFetched) { fetchMonthlyLCDData(); setIsDataFetched(true); }

        const interval = setInterval(fetchMonthlyLCDData, RerfeshDataTimer);
        return () => {
            clearInterval(interval);
        };

    }, [ screenId, companyName])

    const getProductivityStyle = (productivity) => {
        if (productivity < 100) return { background: 'red' };
        if (productivity === 100) return { background: 'blue' };
        if (productivity > 100) return { background: 'green' };
        return {};
    }

    React.useEffect(() => {

        const fetchLCDChartData = () => {
            var url = ``;
            if (companyName == null) {
                url = `${lineInfoByHour}${screenId}`;
            }
            else {
                url = `${lineInfoByHour}${screenId}?companyName=${companyName}`;
            }
            fetch(url)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((respData) => {

                    settableData(respData);
                  
                    setError(null);  // Clear error if data is fetched successfully
                })
                .catch((error) => {
                    console.error('Fetching error:', error);
                    setError('LCD NUMARASI YANLIŞ. LÜTFEN KONTROL EDİNİZ.');  // Set error message
                });
        };
        fetchLCDChartData();

        if (!isDataFetched) { fetchLCDChartData(); setIsDataFetched(true); }

        const interval = setInterval(fetchLCDChartData, RerfeshDataTimer);
        return () => {
            clearInterval(interval);
        };
    }, [])

    return (
        <Carousel
            indicators={false}
            controls={false}
            interval={60000}
            pause={false}>
            <Carousel.Item key={1}>
                <div className="windowSize">
                    <div className="kart-container">
                        <div className="title1">
                            <IntlProvider locale={language} messages={localization[language]}>
                                <FormattedMessage id='targetName' />
                            </IntlProvider>
                        </div>
                        <div className="title2">{sequance}</div>
                        <div className="title3">
                            <IntlProvider locale={language} messages={localization[language]}>
                                <FormattedMessage id='productionName' />
                            </IntlProvider>
                        </div>
                    </div>
                    <div className="kart-container">
                        <div className="title4">
                            <IntlProvider locale={language} messages={localization[language]}>
                                <FormattedMessage id='dailytargetTitle' />
                            </IntlProvider>
                        </div>
                        <div className="title4">
                            <IntlProvider locale={language} messages={localization[language]}>
                                <FormattedMessage id='productionTitle' />
                            </IntlProvider>
                        </div>
                        <div className="title4">
                            <IntlProvider locale={language} messages={localization[language]}>
                                <FormattedMessage id='dailyproductivityTitle' />
                            </IntlProvider>
                        </div>
                    </div>
                    <div className="kart-container">
                        <div className="title5">
                            <div className="half">
                                <div className="box">{dailyTarget}</div>
                            </div>
                            <div className="half">
                                <span>
                                    <IntlProvider locale={language} messages={localization[language]}>
                                        <FormattedMessage id='activeperTitle' />
                                    </IntlProvider>
                                </span>
                                <div className="box">{operatorCount}</div>
                            </div>
                        </div>
                        <div className="title6">
                            <div className="circle" style={getProductivityStyle(productivity)}>% {productivity}</div>
                        </div>
                        <div className="title7">
                            <div className="half">
                                <div className="box2">{production}</div>
                            </div>
                            <div className="half">
                                <span>
                                    <IntlProvider locale={language} messages={localization[language]}>
                                        <FormattedMessage id='kalan' />
                                    </IntlProvider>
                                </span>
                                <div className="box2">{restQuality}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel.Item>
            <Carousel.Item key={2}>
                <div className="windowSize">
                    <div className="kart-container">
                        <div className="title1">
                            <IntlProvider locale={language} messages={localization[language]}>
                                <FormattedMessage id='targetName' />
                            </IntlProvider>
                        </div>
                        <div className="title2">{monthlysequance}</div>
                        <div className="title3">
                            <IntlProvider locale={language} messages={localization[language]}>
                                <FormattedMessage id='productionName' />
                            </IntlProvider>
                        </div>
                    </div>
                    <div className="kart-container">
                        <div className="title4">
                            <IntlProvider locale={language} messages={localization[language]}>
                                <FormattedMessage id='monthlytargetTitle' />
                            </IntlProvider>
                        </div>
                        <div className="title4">
                            <IntlProvider locale={language} messages={localization[language]}>
                                <FormattedMessage id='productionTitle' />
                            </IntlProvider>
                        </div>
                        <div className="title4">
                            <IntlProvider locale={language} messages={localization[language]}>
                                <FormattedMessage id='monthlyproductivityTitle' />
                            </IntlProvider>
                        </div>
                    </div>
                    <div className="kart-container">
                        <div className="title8">
                            <div className="half">
                                <div className="box">{monthlyTarget}</div>
                            </div>                            
                        </div>
                        <div className="title6">
                            <div className="circle" style={getProductivityStyle(productivity)}>% {monthlyproductivity}</div>
                        </div>
                        <div className="title7">
                            <div className="half">
                                <div className="box2">{monthlyproduction}</div>
                            </div>
                            <div className="half">
                                <span>
                                    <IntlProvider locale={language} messages={localization[language]}>
                                        <FormattedMessage id='kalan' />
                                    </IntlProvider>
                                </span>
                                <div className="box2">{monthlyrestQuality}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </Carousel.Item>

            <Carousel.Item key={3}>
                <div className="windowSize" >

                    <LineTable tableData={tableData} lineName={lineName} />

                </div>
            </Carousel.Item>
        </Carousel>
    );
}

export default DailyTargetLineDetail;