import React from "react";
import MessageText from "../components/MessageText"

const NoCompany = (props) => {
    return (<MessageText text="SAYFA BULUNAMADI. LÜTFEN LİNKİN SONUNA FİRMA ADINIZI EKLEYİNİZ." />

    )
};

export default NoCompany;
