import React from "react";
import { Image } from "react-bootstrap";
import itmLogo from "../../../../image/itm-logo-night.jpeg";

const Home = () => {
    return (
        <div
            style={{ height: "100vh" }}
            className="d-flex justify-content-center aling-items-center"
        >
            <Image src={itmLogo} alt="ITM" style={{ height: "auto", width: "100%" }} />
        </div>
    );
};

export default Home;