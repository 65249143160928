import React, { useState } from 'react';
import { Carousel, Table } from "react-bootstrap";
import "./employeeTable.css";
import { useParams } from "react-router-dom";
import { IntlProvider, FormattedMessage } from "react-intl";





const EmployeeTable = ({ employeeData }) => {

    let { screenId } = useParams();

    const filteredEmployeeData = employeeData.filter(item => item.line.lcdNo === parseInt(screenId, 10));

    const sortedEmployeeData = filteredEmployeeData.sort((a, b) => b.productivity - a.productivity);

    const getProductivityStyle = (productivity) => {
        if (productivity <= 60) return { color: 'red' };
        if (productivity <= 80) return { color: 'green' };
        if (productivity < 500) return { color: 'blue' };
        return {};
    }

    const localizationTable = {
        'tr': {
            tableEmployeeName: "Çalışan Adı",
            tableQuantity: "Adet",
            tableProductivity: "Verimlilik %"
        },
        'tr-TR': {
            tableEmployeeName: "Çalışan Adı",
            tableQuantity: "Adet",
            tableProductivity: "Verimlilik %"
        },
        'en-US': {
            tableEmployeeName: "Employee Name",
            tableQuantity: "Quantity",
            tableProductivity: "Productivity %"
        },
        'en': {
            tableEmployeeName: "Employee Name",
            tableQuantity: "Quantity",
            tableProductivity: "Productivity %"
        },
        'ar': {
            tableEmployeeName: "اسم الموظف",
            tableQuantity: "كمية",
            tableProductivity: "% إنتاجية"
        }
    }
    const defaultLocale = navigator.language;

    // Fallback mekanizması ekleyin
    const supportedLocales = ['tr', 'tr-TR', 'en-US', 'ar', 'en'];
    const fallbackLocale = 'en-US';
    const language = supportedLocales.includes(defaultLocale) ? defaultLocale : fallbackLocale;

    const GetEmployeeTable = (employeeData) => {
        let dividedArray = [];
        let tempArray = [];
        for (var index = 0; index < employeeData.length; index++) {
            if (tempArray.length >= 4) {
                dividedArray.push(tempArray);
                tempArray = [];
            }
            tempArray.push(employeeData[index]);
        }

        if (tempArray.length > 0)
            dividedArray.push(tempArray);

        return (
            <Carousel
                indicators={false}
                controls={false}
                interval={7500}
                pause={false}
            >
                {dividedArray.map((subArray, pageIndex) => (
                    <Carousel.Item key={pageIndex}>
                        <div className="LineInfo">
                            <Table className="table LineInfo table-dark table-striped table-bordered">
                                <thead className="thead-light tb_thread">
                                    <tr>
                                        <th>No</th> 
                                        <th>
                                            <IntlProvider locale={language} messages={localizationTable[language]}>
                                                <FormattedMessage id='tableEmployeeName' />
                                            </IntlProvider></th>
                                        <th>
                                            <IntlProvider locale={language} messages={localizationTable[language]}>
                                                <FormattedMessage id='tableQuantity' />
                                            </IntlProvider>
                                        </th>
                                        <th>
                                            <IntlProvider locale={language} messages={localizationTable[language]}>
                                                <FormattedMessage id='tableProductivity' />
                                            </IntlProvider>                                            
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="tb_Lines">
                                    {subArray.map((item, index) => (
                                        <tr key={index}>
                                            <td>{pageIndex * 4 + index + 1}</td>
                                            <td style={{ fontSize: '125px' }}>{item.employee.fullName}</td>
                                            <td>{item.quantity}</td>
                                            <td style={getProductivityStyle(item.productivity)}>{item.productivity} %</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        );
    }

    return GetEmployeeTable(sortedEmployeeData);
};

export default EmployeeTable;


