import React from 'react';
import { Carousel, Table } from 'react-bootstrap';
import { IntlProvider, FormattedMessage } from 'react-intl';
import { FaSmile, FaFrown } from 'react-icons/fa';
import './LineTable.css';

const LineTable = ({ tableData, lineName }) => {
    const localizationTable = {
        'tr': {
            tableHour: "SAAT",
            tableTarget: "HEDEF",
            tableProduction: "ÜRETİM",
            tableEfficiency: "VERİM",
            tableStatus: "DURUM"
        },
        'tr-TR': {
            tableHour: "SAAT",
            tableTarget: "HEDEF",
            tableProduction: "ÜRETİM",
            tableEfficiency: "VERİM",
            tableStatus: "DURUM"
        },
        'en-US': {
            tableHour: "HOUR",
            tableTarget: "TARGET",
            tableProduction: "PRODUCTION",
            tableEfficiency: "EFFICIENCY",
            tableStatus: "STATUS"
        },
        'en': {
            tableHour: "HOUR",
            tableTarget: "TARGET",
            tableProduction: "PRODUCTION",
            tableEfficiency: "EFFICIENCY",
            tableStatus: "STATUS"
        },
        'ar': {
            tableHour: "ساعة",
            tableTarget: "هدف",
            tableProduction: "إنتاج",
            tableEfficiency: "كفاءة",
            tableStatus: "الحالة",
        }
    };

    const defaultLocale = navigator.language;
    const supportedLocales = ['tr', 'tr-TR', 'en-US', 'ar', 'en'];
    const fallbackLocale = 'en-US';
    const language = supportedLocales.includes(defaultLocale) ? defaultLocale : fallbackLocale;

    
    const chunkedData = [];
    for (let i = 0; i < tableData.length; i += 6) {
        chunkedData.push(tableData.slice(i, i + 6));
    }

    return (
        <IntlProvider locale={language} messages={localizationTable[language]}>
            <Carousel indicators={false} controls={false} interval={10000} pause={false}>
                {chunkedData.map((chunk, pageIndex) => (
                    <Carousel.Item key={pageIndex}>
                        <div className="LineInfo">
                            <h2 className="table-header">{lineName}</h2>
                            <Table className="table table-striped table-bordered">
                                <thead className="tb_thread">
                                    <tr>
                                        <th><FormattedMessage id="tableHour" /></th>
                                        <th><FormattedMessage id="tableTarget" /></th>
                                        <th><FormattedMessage id="tableProduction" /></th>
                                        <th><FormattedMessage id="tableEfficiency" /></th>
                                        <th><FormattedMessage id="tableStatus" /></th>
                                    </tr>
                                </thead>
                                <tbody className="tb_Lines">
                                    {chunk.map((row, index) => (
                                        <tr key={index}>
                                            <td>{row.time.substring(0, 5)}</td>
                                            <td>{row.target}</td>
                                            <td>{row.production}</td>
                                            <td>{row.efficiency} %</td>
                                            <td>
                                                {row.efficiency >= 100 ? <FaSmile style={{ color: 'green' }} /> : <FaFrown style={{ color: 'red' }} />}
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        </IntlProvider>
    );
};

export default LineTable;
