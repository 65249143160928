import React, { useState, useEffect } from 'react';
import "../CssDesing/LineDetail.css"
import { Carousel } from "react-bootstrap";
import HourlyProductionChart from "../components/HourlyProductionChart";
import targetImage from "../../../../src/image/icons8-target-96.png"
import { useParams } from "react-router-dom"
import { IntlProvider, FormattedMessage } from "react-intl";
import EmployeeTable from '../components/employeeTable';

const LineDetail = (props) => {
    const RerfeshDataTimer = 120000;

    const lineInfo = process.env.REACT_APP_LINE_INFO_URL;
    const lineInfoByHour = process.env.REACT_APP_LINE_INFO_BY_HOUR_URL;
    const lineEmployeeTable = process.env.REACT_APP_EMPLOYEE_TABLE_URL;

    /*
     const lineInfo = "https://localhost:5126/api/Lcd/GetLineInfo/";
     const lineInfoByHour = "https://localhost:5126/api/Lcd/GetLineInfoByHours?lcdNo=";
     */


    const [isDataFetched, setIsDataFetched] = React.useState(false);
    const [factoryName, setfactoryName] = React.useState();
    const [lineName, setlineName] = React.useState();
    const [operatorCount, setoperatorCount] = React.useState();
    const [target, setTarget] = React.useState();
    const [production, setProduction] = React.useState();
    const [productivity, setProductivity] = React.useState();
    const [firstQuality, setfirstQuality] = React.useState();
    const [secondQuality, setsecondQuality] = React.useState();
    const [repair, setRepair] = React.useState();
    const [chartData, setchartData] = React.useState([]);
    const [employeeData, setemployeeData] = React.useState([]);
    const [hasData, setHasData] = useState(false);
    const [error, setError] = useState(null);
   


    let { screenId } = useParams();
    let { companyName } = useParams();
    //useEffect(() => {
    //        console.log(`${screenId}`)
    //    })

    const localization = {
        'tr': {
            productionTitle: "ÜRETİM",
            productivityTitle: "VERİM",
            activeperTitle: "AKTİF ÇAL:",
            targetTitle: "HEDEF",
            firstqualityTitle: "1.KALİTE",
            secondqualityTitle: "2.KALİTE",
            repairTitle: "TAMİR"


        },
        'tr-TR': {
            productionTitle: "ÜRETİM",
            productivityTitle: "VERİM",
            activeperTitle: "AKTİF ÇAL:",
            targetTitle: "HEDEF",
            firstqualityTitle: "1.KALİTE",
            secondqualityTitle: "2.KALİTE",
            repairTitle: "TAMİR"
        },
        'en-US': {
            productionTitle: "PRODUCTION",
            productivityTitle: "PRODUCTIVITY",
            activeperTitle: "ACTIVE PER:",
            targetTitle: "TARGET",
            firstqualityTitle: "1.QUALITY",
            secondqualityTitle: "2.QUALITY",
            repairTitle: "REPAIR"
        },
        'en': {
            productionTitle: "PRODUCTION",
            productivityTitle: "PRODUCTIVITY",
            activeperTitle: "ACTIVE PER:",
            targetTitle: "TARGET",
            firstqualityTitle: "1.QUALITY",
            secondqualityTitle: "2.QUALITY",
            repairTitle: "REPAIR"
        },
        'ar': {
            productionTitle: "إنتاج",
            productivityTitle: "إنتاجية",
            activeperTitle: ":الموظفون الن",
            targetTitle: "هدف",
            firstqualityTitle: "1.الجودة",
            secondqualityTitle: "2.الجودة",
            repairTitle: "بصلح"
        }
    }

    const defaultLocale = navigator.language;

    // Fallback mekanizması ekleyin
    const supportedLocales = ['tr', 'tr-TR', 'en-US', 'ar' , 'en'];
    const fallbackLocale = 'en-US';
    const language = supportedLocales.includes(defaultLocale) ? defaultLocale : fallbackLocale;

    React.useEffect(() => {
        const fetchLCDData = () => {
            var url = ``;
            if (companyName == null) {
                url = `${lineInfo}${screenId}`;
            }
            else {
                url = `${lineInfo}${screenId}?companyName=${companyName}`;
            }
            fetch(url)
                .then((response) => response.json())
                .then((respData) => {
                    const respfactoryName = respData?.factoryName || "";
                    const respLineName = respData?.lineName || "";
                    const respoperatorCount = respData?.operatorCount || [];
                    const resptarget = respData?.target || [];
                    const respproduction = respData?.production || [];
                    const respproductivity = respData?.productivity || [];
                    const respfirstQuality = respData?.firstQuality || [];
                    const respsecondQuality = respData?.secondQuality || [];
                    const resprepair = respData?.repair || [];


                    setfactoryName(respfactoryName);
                    setlineName(respLineName);
                    setoperatorCount(respoperatorCount);
                    setTarget(resptarget);
                    setProduction(respproduction);
                    setProductivity(respproductivity);
                    setfirstQuality(respfirstQuality);
                    setsecondQuality(respsecondQuality);
                    setRepair(resprepair);
                    console.log(respData);
                })

        }

        fetchLCDData();

        // const hasGetLineInfo = fetchLCDData.data.leght > 0


        if (!isDataFetched) { fetchLCDData(); setIsDataFetched(true); }

        const interval = setInterval(fetchLCDData, RerfeshDataTimer);
        return () => {
            clearInterval(interval);
        };

    }, [lineInfo, lineInfoByHour, screenId, companyName])

    React.useEffect(() => {

        const fetchLCDChartData = () => {
            var url = ``;
            if (companyName == null) {
                url = `${lineInfoByHour}${screenId}`;
            }
            else {
                url = `${lineInfoByHour}${screenId}?companyName=${companyName}`;
            }
            console.log(url);
            fetch(url)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json();
                })
                .then((respData) => {

                    setchartData(respData);
                    console.log(respData);
                    setError(null);  // Clear error if data is fetched successfully
                })
                .catch((error) => {
                    console.error('Fetching error:', error);
                    setError('LCD NUMARASI YANLIŞ. LÜTFEN KONTROL EDİNİZ.');  // Set error message
                });
        };       
        fetchLCDChartData();

       // const hasChartData = setchartData.data.some(item => item.production > 0);

        if (!isDataFetched) { fetchLCDChartData(); setIsDataFetched(true); }

        const interval = setInterval(fetchLCDChartData, RerfeshDataTimer);
        return () => {
            clearInterval(interval);
        };

    }, [])

    React.useEffect(() => {

        const fetchEmployeeData = () => {
            var url = ``;
            if (companyName == null) {
                url = `${lineEmployeeTable}`;
            }
            else {
                url = `${lineEmployeeTable}?companyName=${companyName}`;
            }
            console.log(url);
            fetch(url)
                .then((response) => response.json())
                .then((respData) => {

                    setemployeeData(respData);
                    console.log(respData)
                })
        }
        fetchEmployeeData();

       // const hasEmployeeData = fetchEmployeeData.data.leght > 0

        if (!isDataFetched) { fetchEmployeeData(); setIsDataFetched(true); }

        const interval = setInterval(fetchEmployeeData, RerfeshDataTimer);
        return () => {
            clearInterval(interval);
        };

    }, [])  


    return (
        <Carousel
            indicators={false}
            controls={false}
            interval={22500}
            pause={false}>
            <Carousel.Item key={1}>
                <div className="windowSize">
                    <div className="kart-container">
                        <div className="kart1"> {factoryName} </div>
                        <div className="kart2"> {lineName} </div>
                        <div className="kart3">
                            <IntlProvider locale={language} messages={localization[language]}>
                                <FormattedMessage id='activeperTitle' />
                            </IntlProvider> {operatorCount}
                        </div>
                    </div>
                    <div className="kart-container">
                        <div className="kart4">
                            <img src={targetImage} style={{ height: "8vh", width: "auto" }} />
                            <IntlProvider locale={language} messages={localization[language]}>
                                <FormattedMessage id='targetTitle' />
                            </IntlProvider>
                        </div>
                        <div className="kart4">
                            <IntlProvider locale={language} messages={localization[language]}>
                                <FormattedMessage id='productionTitle' />
                            </IntlProvider>
                        </div>
                        <div className="kart4">
                            <IntlProvider locale={language} messages={localization[language]}>
                                <FormattedMessage id='productivityTitle' />
                            </IntlProvider>
                        </div>
                    </div>
                    <div className="kart-container">
                        <div className="kart5"> {target} </div>
                        <div className="kart5"> {production} </div>
                        <div className="kart5"> %{productivity} </div>
                    </div>
                    <div className="kart-container">
                        <div className="kart4">
                            <IntlProvider locale={language} messages={localization[language]}>
                                <FormattedMessage id='firstqualityTitle' />
                            </IntlProvider>
                        </div>
                        <div className="kart4">
                            <IntlProvider locale={language} messages={localization[language]}>
                                <FormattedMessage id='secondqualityTitle' />
                            </IntlProvider>
                        </div>
                        <div className="kart4">
                            <IntlProvider locale={language} messages={localization[language]}>
                                <FormattedMessage id='repairTitle' />
                            </IntlProvider>
                        </div>
                    </div>
                    <div className="kart-container">
                        <div className="kart6"> {firstQuality} </div>
                        <div className="kart6"> {secondQuality} </div>
                        <div className="kart6"> {repair} </div>
                    </div>
                </div>
            </Carousel.Item>

            <Carousel.Item key={2}>
                <div className="windowSize" style={{ backgroundColor: "#def9f7" }}>
                    {error ? (
                        <div style={{ fontSize: '50px', textAlign: 'center', marginTop: '30px' }}>
                            {error}
                        </div>
                    ) : (
                            chartData && <HourlyProductionChart chartData={chartData} />
                    ) }
                </div>
            </Carousel.Item>

            <Carousel.Item key={3}>
                <div className="windowSize" >
                    
                    <EmployeeTable employeeData={employeeData} />

                </div>
            </Carousel.Item>

        </Carousel>
    );
}

export default LineDetail;