import React, { Component } from 'react';
import { Route, Routes, Redirect } from 'react-router-dom';
import LineDetail from './conteiners/LineEndingScreen/conteiners/LineDetail';
import EmployeeTable from './conteiners/LineEndingScreen/components/employeeTable';
import NoMatch from './conteiners/LineEndingScreen/components/NoMatch';
import NoCompany from './conteiners/LineEndingScreen/components/NoCompany'
import Home from './conteiners/LineEndingScreen/components/Home/Home';
import FactoryLineTable from './conteiners/LineEndingScreen/conteiners/FactoryLineTable';
import DailyTargetLineDetail from './conteiners/LineEndingScreen/conteiners/DailyTargetLineDetail';
/*import './custom.css';*/

const App = () => {

    return (
        <Routes>
            <Route path="/" >
                <Route path="/" element={(<Home />)} />
                <Route path="*" element={(<NoMatch />)} />
                <Route path="LCD2/:companyName" element={(<FactoryLineTable />)} />
                <Route path="LCD/:screenId/" element={(<NoCompany />)} />
                <Route path="LCD/:screenId/:companyName" element={(<LineDetail />)} />
                <Route path="LCD3/:screenId/:companyName" element={(<DailyTargetLineDetail />)} />
                <Route path="LCD/:screenId/" element={(<LineDetail />, <EmployeeTable />)} />                
            </Route>
        </Routes>
    );
};


export default App;
