import React, { useState } from 'react';
import { Carousel, Table } from "react-bootstrap";
import "../components/FactoryLineTable.css";
import { useParams } from "react-router-dom";
import { IntlProvider, FormattedMessage } from "react-intl";





const FactoryLineTable = (props) => {

    /*    let { com } = useParams();*/
    let { companyName } = useParams();
    const RerfeshDataTimer = 120000;


    //const filteredEmployeeData = employeeData.filter(item => item.line.lcdNo === parseInt(screenId, 10));

    

    const lineInfo = process.env.REACT_APP_LINE_INFO_URL;

    const [factoryLineData, setFactoryLineData] = React.useState([]);
    const [isDataFetched, setIsDataFetched] = React.useState(false);


    React.useEffect(() => {

        const fetchFactoryLineDetail = () => {
            var url = ``;
            if (companyName == null) {
                url = `${lineInfo}`;
            }
            else {
                url = `${lineInfo}?companyName=${companyName}`;
            }
            console.log(url);
            fetch(url)
                .then((response) => response.json())
                .then((respData) => {

                    setFactoryLineData(respData);
                    console.log(respData)
                })
        }
        fetchFactoryLineDetail();

        // const hasEmployeeData = fetchEmployeeData.data.leght > 0

        if (!isDataFetched) { fetchFactoryLineDetail(); setIsDataFetched(true); }

        const interval = setInterval(fetchFactoryLineDetail, RerfeshDataTimer);
        return () => {
            clearInterval(interval);
        };

    }, [])  


    const sortedFactoryLineData = factoryLineData.sort((a, b) => a.lineName.localeCompare(b.lineName));

    const getProductivityStyle = (productivity) => {
        if (productivity <= 60) return { color: 'red' };
        if (productivity <= 80) return { color: 'green' };
        if (productivity < 500) return { color: 'blue' };
        return {};
    }

    const localizationTable = {
        'tr': {
            tableFactoryLineName: "BANT",
            tableQuantity: "ÜRETİM",
            tableProductivity: "VERİMLİLİK %",
            tableTarget: "HEDEF",
            tableWorkers: "AKTİF Ç."
        },
        'tr-TR': {
            tableFactoryLineName: "BANT",
            tableQuantity: "ÜRETİM",
            tableProductivity: "VERİMLİLİK %",
            tableTarget: "HEDEF",
            tableWorkers: "AKTİF Ç."
        },
        'en-US': {
            tableFactoryLineName: "LINE",
            tableQuantity: "Quantity",
            tableProductivity: "Productivity %"
        },
        'en': {
            tableFactoryLineName: "LINE",
            tableQuantity: "Quantity",
            tableProductivity: "Productivity %"
        },
        'ar': {
            tableFactoryLineName: "اسم الموظف",
            tableQuantity: "كمية",
            tableProductivity: "% إنتاجية"
        }
    }
    const defaultLocale = navigator.language;

    // Fallback mekanizması ekleyin
    const supportedLocales = ['tr', 'tr-TR', 'en-US', 'ar', 'en'];
    const fallbackLocale = 'en-US';
    const language = supportedLocales.includes(defaultLocale) ? defaultLocale : fallbackLocale;

    const GetFactoryLineTable = (factoryLineData) => {
        let dividedArray = [];
        let tempArray = [];
        for (var index = 0; index < factoryLineData.length; index++) {
            if (tempArray.length >= 4) {
                dividedArray.push(tempArray);
                tempArray = [];
            }
            tempArray.push(factoryLineData[index]);
        }

        if (tempArray.length > 0)
            dividedArray.push(tempArray);

        return (
            <Carousel
                indicators={false}
                controls={false}
                interval={15000}
                pause={false}
            >
                {dividedArray.map((subArray, pageIndex) => (
                    <Carousel.Item key={pageIndex}>
                        <div className="LineInfo">
                            <Table className="table LineInfo  table-striped table-bordered">
                                <thead className="thead-light tb_thread">
                                    <tr>
                                        <th>
                                            <IntlProvider locale={language} messages={localizationTable[language]}>
                                                <FormattedMessage id='tableFactoryLineName' />
                                            </IntlProvider>
                                        </th>
                                        <th>
                                            <IntlProvider locale={language} messages={localizationTable[language]}>
                                                <FormattedMessage id='tableWorkers' />
                                            </IntlProvider>
                                        </th>
                                        <th>
                                            <IntlProvider locale={language} messages={localizationTable[language]}>
                                                <FormattedMessage id='tableTarget' />
                                            </IntlProvider>
                                        </th>
                                        <th>
                                            <IntlProvider locale={language} messages={localizationTable[language]}>
                                                <FormattedMessage id='tableQuantity' />
                                            </IntlProvider>
                                        </th>
                                        
                                        <th>
                                            <IntlProvider locale={language} messages={localizationTable[language]}>
                                                <FormattedMessage id='tableProductivity' />
                                            </IntlProvider>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="tb_Lines">
                                    {subArray.map((item, index) => (
                                        <tr key={index}>                                            
                                            <td style={{ fontSize: '125px' }}>{item.lineName}</td>
                                            <td>{item.operatorCount}</td>
                                            <td style={{ color: "orange" }}>{item.target}</td>
                                            <td>{item.production}</td>                                            
                                            <td style={getProductivityStyle(item.productivity)}>{item.productivity} %</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Carousel.Item>
                ))}
            </Carousel>
        );
    }

    return GetFactoryLineTable(sortedFactoryLineData);
};

export default FactoryLineTable;


