import React, { PureComponent, useState, useEffect } from 'react';
import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,
    Line,
    ComposedChart

} from 'recharts';
import { IntlProvider, FormattedMessage } from "react-intl";


const HourlyProductionChart = ({ chartData }) => {
    const data = chartData.map((item) => ({
        name: item.time.substring(0, 5),
        Uretim: item.production,
        Hedef: item.target,
    }));




    const localizationChart = {
        'tr': {
            chartTitle: "SİPARİŞ ÜRETİM ADETLERİ",
            chartproductionTitle: "Üretim",
            charttargetTitle: "Hedef"
        },
        'tr-TR': {
            chartTitle: "SİPARİŞ ÜRETİM ADETLERİ",
            chartproductionTitle: "Üretim",
            charttargetTitle: "Hedef"
        },
        'en-US': {
            chartTitle: "ORDER PRODUCTION QUANTITIES",
            chartproductionTitle: "Production",
            charttargetTitle: "Target"
        },
        'en': {
            chartTitle: "ORDER PRODUCTION QUANTITIES",
            chartproductionTitle: "Production",
            charttargetTitle: "Target"
        },
        'ar': {
            chartTitle: "سيباريس أوريتيم أديتليري",
            chartproductionTitle: "إنتاج",
            charttargetTitle: "هدف"
        }
    }

    const defaultLocale = navigator.language;

    // Fallback mekanizması ekleyin
    const supportedLocales = ['tr', 'tr-TR', 'en-US', 'ar', 'en'];
    const fallbackLocale = 'en-US';
    const language = supportedLocales.includes(defaultLocale) ? defaultLocale : fallbackLocale;

    return (
        <ResponsiveContainer width="100%" height="100%">
            <ComposedChart
                data={data}
                margin={{
                    top: 75, right: 10, bottom: 20, left: 5,
                }}
            >
                <text x={440} y={30} fill="black" textAnchor="CENTER" dominantBaseline="central" >
                    <tspan fontSize="4vw">
                        <IntlProvider locale={language} messages={localizationChart[language]}>
                            <FormattedMessage id='chartTitle' />
                        </IntlProvider>
                    </tspan>
                </text>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" fontSize="2.5vw" />
                <YAxis
                    fontSize={20}
                /*  tick={{ fill: "#fffff" }}    */
                />
                <Tooltip />
                <Legend wrapperStyle={{ lineHeight: '40px', fontSize: "2vw" }} iconSize="30px" />
                <Line
                    type="monotone"
                    dataKey="Hedef"
                    stroke="red"
                    activeDot={{ r: 10 }}
                    strokeWidth={2}
                    lineHeight={2}
                    name={
                        <IntlProvider locale={language} messages={localizationChart[language]}>
                            <FormattedMessage id='charttargetTitle' />
                        </IntlProvider>
                    } />
                <Bar 
                    dataKey="Hedef"
                    name={
                        <IntlProvider locale={language} messages={localizationChart[language]}>
                            <FormattedMessage id='charttargetTitle' />
                        </IntlProvider>
                    }
                    fill="#2196f3"
                    minPointSize={3} />
                <Bar
                    dataKey="Uretim"
                    name={
                        <IntlProvider locale={language} messages={localizationChart[language]}>
                            <FormattedMessage id='chartproductionTitle' />
                        </IntlProvider>
                    }
                    fill="#7c6797"
                    minPointSize={3} />
            </ComposedChart>
        </ResponsiveContainer>
    );
};



export default HourlyProductionChart;
